var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vs-table",
    {
      attrs: { data: _vm.formattedData, sst: true, "current-sort-type": "asc" },
      on: { sort: _vm.handleSort },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function (ref) {
            var data = ref.data
            return _vm._l(data, function (tr, i) {
              return _c(
                "vs-tr",
                { key: data[i], class: "_data-viewer_" + tr.key },
                [
                  _c("vs-td", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("data_viewer.keys." + tr.key)) + " "
                    ),
                  ]),
                  _c(
                    "vs-td",
                    [
                      _c("vs-td", { staticClass: "break-all" }, [
                        _c("div", {
                          directives: [
                            {
                              name: "html-safe",
                              rawName: "v-html-safe",
                              value: _vm.formatValue(tr.value),
                              expression: "formatValue(tr.value)",
                            },
                          ],
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            })
          },
        },
      ]),
    },
    [
      _c(
        "template",
        { slot: "thead" },
        [
          _c("vs-th", { attrs: { "sort-key": "key" } }, [
            _vm._v(_vm._s(_vm.$t("informacao"))),
          ]),
          _c("vs-th", { attrs: { "sort-key": "value" } }, [
            _vm._v(_vm._s(_vm.$t("valor"))),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }