<template>
  <vs-table :data="formattedData" @sort="handleSort" :sst="true" current-sort-type="asc">
    <template slot="thead">
      <vs-th sort-key="key">{{ $t('informacao') }}</vs-th>
      <vs-th sort-key="value">{{ $t('valor') }}</vs-th>
    </template>

    <template slot-scope="{data}">
      <vs-tr :class="`_data-viewer_${tr.key}`" :key="data[i]" v-for="(tr, i) in data">
        <vs-td>
          {{ $t(`data_viewer.keys.${tr.key}`) }}
        </vs-td>
        <vs-td>
          <vs-td class="break-all">
            <div v-html-safe="formatValue(tr.value)"/>
          </vs-td>
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
import DataViewerMappings from '@/util/DataViewerMappings'

export default {
  created() {
    this.mappings = DataViewerMappings
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    }
  },

  data: () => ({
    mappings: null
  }),

  computed: {
    formattedData() {
      if (this._.isObject(this.data)) {
        return Object.entries(this.data).map(([key, value]) => {
          return  {
            key: key,
            value: this.getValue(key, value)
          }
        })
      } else if (this._.isArray(this.data)) {
        return this.data.map((value, key) => {
          return {
            key: key,
            value: this.getValue(key, value)
          }
        })
      } else {
        return [
          {
            key: '_data-viewer_warning',
            value: this.$t('tipo-de-variavel-nao-suportada')
          }
        ]
      }
    }
    
  },

  methods: {
    getValue(key, value) {
      if (key in this.mappings) return this.mappings[key](value, this)
      else return value
    },
    formatValue(value) {
      if (typeof value === 'string' || value instanceof String) {
        return value
      }
      return JSON.stringify(value)
    }
  }
}
</script>

<style>

</style>